<template>
  <ul class="stats row" :class="`stats-${list.length}`">
    <li
      v-for="(item, index) in list"
      :key="index"
      :style="{ animationDelay: delay + index * 0.2 + 's' }"
      class="stat row-item"
    >
      <sanity-content
        :blocks="item"
        :serializers="{
          styles: { normal: 'p', descriptor: 'span', secondary: 'p' },
        }"
      />
    </li>
  </ul>
</template>

<script lang="ts" setup>
import type { SanityBlock } from '~/types'

const props = defineProps({
  blocks: Array as () => SanityBlock[],
  delay: { type: Number, default: 0 },
})

const list = computed(() => {
  const result = []

  props.blocks?.forEach((element) => {
    if (element.style === 'normal') result.push([])
    result[result.length - 1].push({
      ...element,
      class: 'stat-' + (element.style === 'normal' ? 'heading' : element.style),
    })
  })

  return result
})
</script>

<style lang="scss">
.stats {
  position: relative;
  display: flex;
  width: calc(100% + 2rem);
  margin: 0 -1rem $xl;
  justify-content: space-evenly;

  @include media('<=phone') {
    justify-content: flex-start;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .stat {
    display: flex;
    flex-flow: column;
    margin: 1rem;
    max-width: 100%;

    > * {
      animation: none !important;
    }

    &-heading,
    &-secondary {
      font: {
        family: var(--font-primary);
        style: italic;
        weight: 600;
        size: 2.6em;
      }
      color: var(--secondary);

      @include media('<tablet') {
        font-size: 2em;
        text-align: left;
      }

      em {
        font-size: 0.6em;
        color: var(--body);

        &:first-child {
          vertical-align: topl
        }
      }
    }
    &-heading {
    }
    &-descriptor {
      font-size: 1.4em;
      // font-weight: 400;
      text-align: right;
      // margin-bottom: $md;

      @include media('<tablet') {
        font-size: 1.2em;
      }
    }
    &-secondary {
      font-size: 1.2em;
      margin-top: $md;
      text-align: right;
      line-height: 0.8;

      @include media('<tablet') {
        text-align: left;
      }
    }
  }

  &.stats-2 {
    width: calc(100% + 4rem);
    margin: 0 -2rem $xl;

    .stat {
      font-size: 1.4em;
      margin: 2rem;
    }

    @include media('<tablet') {
      width: calc(100% + 2rem);
      margin: 0 -1rem $xl;

      .stat {
        margin: 2rem 1rem;
      }
    }
  }
}
</style>
